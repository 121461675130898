<template>
  <div>
    <v-card height="100vh" flat>
      <v-img
        class="px-5"
        height="100vh"
        :src="`uploads/${aboutImage}`"
        gradient="to top right, rgba(255,255,225,.9), rgba(0,0,0,.6)"
      >
        <v-container>
          <v-row class="mt-5" justify="center">
            <v-card height="100" color="transparent" flat width="100"
              ><v-img src="camera.png" height="100" width="100" contain></v-img
            ></v-card>
          </v-row>

          <v-row justify="center">
            <div class="mt-5" style="width: 800px">
              <h2
                :contenteditable="isLoggedIn"
                ref="aboutTitle"
                :style="{ color: titleFontColor }"
                class="abt-title text-center px-sm-10 pX-lg-4"
              >
                {{ dform.aboutTitle }}
              </h2>
              <p
                :contenteditable="isLoggedIn"
                ref="aboutSubTitle1"
                :style="{ color: textFontColor }"
                class="abt-subtitle mt-5 px-sm-10 pX-lg-4"
              >
                {{ dform.aboutSubTitle1 }}
              </p>
              <p
                :contenteditable="isLoggedIn"
                ref="aboutSubTitle2"
                :style="{ color: textFontColor }"
                class="abt-subtitle mt-5 px-sm-10 pX-lg-4"
              >
                {{ dform.aboutSubTitle2 }}
              </p>
            </div>
          </v-row>
          <p class="text-center mt-7">
            <v-icon color=" secondary">mdi-camera-iris</v-icon>
          </p>
        </v-container>

        <div v-if="isLoggedIn" class="mt-15 mr-md-15 mr-sm-6 text-end">
          <input
            id="aboutCoverImage"
            hidden
            type="file"
            accept="image/*"
            @change="handleAboutImage"
          />
          <label for="aboutCoverImage">
            <v-icon title="Change Image" class="rounded-circle secondary pa-3"
              >mdi-camera</v-icon
            >
          </label>

          <v-btn
            class="ml-2"
            :loading="loading"
            @click="handleAboutData"
            small
            bottom
            color="accent"
          >
            <b> save </b></v-btn
          >
        </div>
      </v-img>
    </v-card>
    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p class="secondary--text">{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon color="secondary">mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin } from "@/mixins";
import { apiClient } from "@/services";
import { mapGetters } from "vuex";
export default {
  mixins: [snackMixin],
  props: {
    titleFontColor: {
      type: String,
    },
    textFontColor: {
      type: String,
    },
  },
  data() {
    return {
      aboutImage: "",
      dform: {
        aboutTitle: "",
        aboutSubTitle1: "",
        aboutSubTitle2: "",
      },
    };
  },

  created() {
    apiClient
      .get("/pages/load-about")
      .then((res) => {
        this.dform = res.data.about;
        this.aboutImage = res.data.about.aboutImage;
      })
      .catch((err) => {
        this.displayMsg(err.message, "error");
      });
  },

  computed: {
    ...mapGetters(["isLoggedIn"]),
  },

  methods: {
    async handleAboutImage(event) {
      let data = new FormData();
      data.append("name", "file");
      data.append("file", event.target.files[0]);
      try {
        const res = await apiClient.put("/pages/change-about-image", data);

        this.aboutImage = res.data.aboutImage;
        this.displayMsg("Updated successfully", "success");
      } catch (error) {
        this.displayMsg(error.message, "error");
      }
    },

    async handleAboutData() {
      this.dform.aboutTitle = this.$refs.aboutTitle.innerText;
      this.dform.aboutSubTitle1 = this.$refs.aboutSubTitle1.innerText;
      this.dform.aboutSubTitle2 = this.$refs.aboutSubTitle2.innerText;

      try {
        const res = await apiClient.put("/pages/about", {
          aboutTitle: this.dform.aboutTitle,
          aboutSubTitle1: this.dform.aboutSubTitle1,
          aboutSubTitle2: this.dform.aboutSubTitle2,
        });
        this.dform = res.data.about;
        this.displayMsg("Updated successfully", "success");
      } catch (error) {
        this.displayMsg(error.message, "error");
      }
    },
  },
};
</script>

<style scoped>
.abt-title {
  font-size: 45px;
}
.abt-subtitle {
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .abt-title {
    font-size: 30px;
  }
  .abt-subtitle {
    font-size: 14px;
  }
}
</style>
